import React from 'react'
import PropTypes from 'prop-types'

const ArrowNext = props => {
    const { CircleColor, ArrowColor } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
        >
            <g transform="translate(0 -0.098)">
                <rect
                    width="26"
                    height="26"
                    rx="13"
                    transform="translate(0 0.098)"
                    fill={CircleColor}
                    opacity="0.05"
                />
                <g transform="translate(8 10.402)">
                    <path
                        d="M3,12h9.619"
                        transform="translate(-3 -9)"
                        fill="none"
                        stroke={ArrowColor}
                        strokeLinecap="round"
                        strokeWidth="1"
                        fillRule="evenodd"
                    />
                    <path
                        d="M13,4l3,3-3,3"
                        transform="translate(-5.912 -4)"
                        fill="none"
                        stroke={ArrowColor}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        fillRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    )
}

ArrowNext.propTypes = {
    CircleColor: PropTypes.string,
    ArrowColor: PropTypes.string,
}

ArrowNext.defaultProps = {
    CircleColor: '#303030',
    ArrowColor: '#c1c1c1',
}

export default ArrowNext
