import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
const ReactMarkdown = require('react-markdown')

const FaqItem = props => {
    const [isOpen, setIsOpen] = useState(false)
    const AnswerContentItem = useRef(null)
    const { question, desc, index } = props

    const handleToggle = () => {
        setIsOpen(!isOpen)
        console.log()
    }

    return (
        <>
            <FaqQuestionWrapper onClick={handleToggle}>
                <h3> {question}</h3>
                <FaqSimbol>{isOpen ? '-' : '+'}</FaqSimbol>
            </FaqQuestionWrapper>
            <FaqAnswerWrapper ref={AnswerContentItem}>
                <FaqAnswerContent
                    className={isOpen ? 'active' : ''}
                    data-id={index}
                >
                    <div>
                        <ReactMarkdown source={desc} />
                    </div>
                </FaqAnswerContent>
            </FaqAnswerWrapper>
        </>
    )
}

const Faq = props => {
    const { faQsList } = props.data

    return (
        <FaqSection>
            {faQsList &&
                faQsList.map((item, index) => (
                    <FaqItem
                        index={index}
                        question={item.question}
                        desc={item.answer.answer}
                        key={item.question}
                    />
                ))}
        </FaqSection>
    )
}

export default Faq

const FaqSection = styled.section`
    padding: 15px;
    margin: 50px auto;
    max-width: 1200px;
    counter-reset: questionitem;
`

const FaqQuestionWrapper = styled.div`
    cursor: pointer;
    box-shadow: 0px 2px 1px -1px rgba(141, 198, 63, 0.2),
        0px 1px 1px 0px rgba(141, 198, 63, 0.14),
        0px 1px 3px 0px rgba(141, 198, 63, 0.12);
    padding: 30px 20px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.GREEN};
    color: #fff;

    h3 {
        margin-bottom: 0;
        font-size: 20px;
        &:before {
            counter-increment: questionitem;
            content: counter(questionitem) ': ';
        }
    }
`
const FaqSimbol = styled.span`
    font-size: 26px;
`
const FaqAnswerWrapper = styled.div`
    margin-bottom: 30px;
`

const FaqAnswerContent = styled.div`
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    & div {
        padding: 20px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        font-size: 16px;
        p {
            margin-bottom: 0;
        }
    }

    &.active {
        max-height: 800px;
        & div {
            opacity: 1;
        }
    }
`
