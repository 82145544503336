import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import styled from 'styled-components'
import Image from 'gatsby-image'
import customMedia from '../../utils/media'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox'
import 'react-popupbox/dist/react-popupbox.css'

const ReactMarkdown = require('react-markdown')

const Team = props => {
    const { members } = props.data
    const popupboxConfig = {
        titleBar: {
            enable: true,
            text: 'Hoja de Vida',
        },
        fadeIn: true,
        fadeInSpeed: 500,
    }
    return (
        <Background>
            <Section>
                <Content>
                    <ContentTitle>PRINCIPALES EJECUTIVOS</ContentTitle>
                    <p style={{ marginBottom: 55 }}>
                        Mediante Resolución de Acreditación Fiduciaria
                        FID-A-0057-2018 del 7 de Mayo de 2018.
                    </p>
                </Content>
                {members.map((member, i) => {
                    const openPopupbox = () => {
                        const content = (
                            <Flex>
                                <Box width={[1, 1]}>
                                    <ReactMarkdown
                                        source={member.content.content}
                                    />
                                </Box>
                            </Flex>
                        )
                        PopupboxManager.open({ content })
                    }
                    return (
                        <div style={{ marginBottom: 40 }}>
                            <Flex alignItems="center" flexWrap="wrap">
                                <Box textAlign="center" width={[1, 1, '30%']}>
                                    <Image
                                        style={{ maxWidth: '70%' }}
                                        fluid={member.thumbnails.fluid}
                                    />
                                </Box>
                                <Box width={[1, 1, '70%']}>
                                    <Content>
                                        <ContentTitle>
                                            {member.title}
                                        </ContentTitle>
                                        <div>
                                            <ReactMarkdown
                                                source={
                                                    member.shortDescription
                                                        .shortDescription
                                                }
                                            />
                                        </div>
                                        {member.withModal && (
                                            <Buttom onClick={openPopupbox}>
                                                Hoja de Vida
                                            </Buttom>
                                        )}
                                    </Content>
                                </Box>
                            </Flex>
                        </div>
                    )
                })}
                <PopupboxContainer
                    css={{
                        position: `relative`,
                        [customMedia.greaterThan('widescreen')]: {
                            minWidth: 500,
                        },
                    }}
                    {...popupboxConfig}
                />
            </Section>
        </Background>
    )
}

export default Team

const Background = styled.section`
    background: url('http://images.ctfassets.net/6eiz3d8al9bt/7ioS9N7PiiXUtEmjpAwNGt/3214be0f19c714f3011bf958ced04f7f/hero-FAQ.jpg')
        center center;
    repeat: no-repeat;
    background-size: cover;
    padding: 65px 0 40px 0;
    position: relative;
    margin-bottom: 0;
    top: 0;
    &:before {
        content: '';
        position: absolute;
        background: #0f153cba;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
    }
`

const Section = styled.div`
    margin: 0 auto;
    max-width: 90%;
    position: relative;
    z-index: 4;
    padding: 0 100px;
`
const Content = styled.div`
    color: #fff;
`
const ContentTitle = styled.h2`
    font-size: 30px;
    color: #fff;
`
const Buttom = styled.button`
    margin-top: 20px;
    background: ${({ theme, isBlue }) =>
        isBlue ? theme.colors.BLUE : theme.colors.GREEN};
    color: #fff;
    padding: 10px 31px;
    text-decoration: none;
    border-radius: 28px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border: none;
`
