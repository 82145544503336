import React from 'react'
import styled, { css } from 'styled-components'

const ReactMarkdown = require('react-markdown/with-html')

const Content = props => {
    const { bodyContent } = props.data
    return (
        <Section>
            {
                <ReactMarkdown
                    escapeHtml={false}
                    source={bodyContent.bodyContent}
                />
            }
        </Section>
    )
}

export default Content

const Section = styled.section`
    padding: 100px;
    max-width: 90%;
    margin: 0 auto;

    h2 {
        color: ${({ theme }) => theme.colors.GREEN};
    }
`
