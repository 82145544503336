import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Modules from '../components/modules'

export default props => {
    const Page = props.data.contentfulPage || ''
    const { seoTitle, seoDescription, modules, seoImage } = Page
    return (
        <Layout>
            <Seo
                title={seoTitle}
                description={seoDescription}
                image={(seoImage && seoImage.fluid.src) || ''}
            />

            <Modules modules={modules} />
        </Layout>
    )
}

export const PageQuery = graphql`
    query($id: String!) {
        contentfulPage(id: { eq: $id }) {
            pageName
            seoTitle
            seoDescription
            seoImage {
                fluid {
                    src
                }
            }
            slug
            modules {
                __typename
                ... on ContentfulComponentHero {
                    heroTitle
                    heroText
                    ctaText
                    ctaLink
                    image {
                        fluid(quality: 100) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                    linksItems {
                        slug
                        name
                    }
                }
                ... on ContentfulComponentCta {
                    title
                    text
                    ctaText
                    ctaLink
                    backgroundImage {
                        fluid(quality: 100) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
                ... on ContentfulComponentFaq {
                    faQsList {
                        question
                        answer {
                            answer
                        }
                    }
                }

                ... on ContentfulComponentImageContent {
                    sectionTitle
                    sectionContent {
                        sectionContent
                    }
                    sectionImage {
                        fluid(quality: 100) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                    isReverse
                    ctaText
                    ctaSlug
                }
                ... on ContentfulComponentContent {
                    bodyContent {
                        bodyContent
                    }
                }
                ... on ContentfulTeamList {
                    members {
                        title
                        content {
                            content
                        }
                        shortDescription {
                            shortDescription
                        }
                        thumbnails {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                        }
                        withModal
                    }
                }
            }
        }
    }
`
