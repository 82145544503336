import React, { useRef, useState, useEffect } from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import { Flex, Box } from 'reflexbox/styled-components'
import styled from 'styled-components'
import customMedia from '../../utils/media'

import Arrow from '../Shared/Arrow'

const HeroLinks = props => {
    const { links } = props
    return (
        <HeroLinksWrapper>
            <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
            >
                {links &&
                    links.map(link => (
                        <HeroLinkItemBox key={link.slug} width={[1, 1, 1 / 4]}>
                            <HeroLinkItem to={`/${link.slug}`}>
                                <span> {link.name}</span>
                                <Arrow />
                            </HeroLinkItem>
                        </HeroLinkItemBox>
                    ))}
            </Flex>
        </HeroLinksWrapper>
    )
}

export default HeroLinks

HeroLinks.propTypes = {
    links: PropTypes.array,
}

const HeroLinksWrapper = styled.section`
    position: absolute;
    width: 100%;
    bottom: 0;
    max-width: 1170px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: none;
    ${customMedia.greaterThan('desktop')`
      display:block
  `}
`
const HeroLinkItem = styled(Link)`
    background: #fff;
    border-radius: 5px 5px 0 0;
    color: ${({ theme }) => theme.colors.GREEN};
    padding: 10px;
    font-size: 20px;
    height: 133px;
    transition: all ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    & span {
        max-width: 75%;
    }
`
const HeroLinkItemBox = styled(Box)`
    padding: 0 15px;

    &:hover ${HeroLinkItem} {
        color: #fff;

        font-style: italic;
        background: ${({ theme }) => theme.colors.GREEN};
    }
`
