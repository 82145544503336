import { generateMedia } from 'styled-media-query'

const customMedia = generateMedia({
    widescreen: '1440px',
    desktop: '1170px',
    tablet: '768px',
    mobile: '450px',
})

export default customMedia
