import React from 'react'
import styled from 'styled-components'

export default props => {
    const { isActive } = props
    return (
        <Hamburguer
            className={`${isActive} hamburger--collapse`}
            onClick={props.onClick}
            type="button"
        >
            <HamburguerBox>
                <HamburguerInner />
            </HamburguerBox>
        </Hamburguer>
    )
}

const HamburguerInner = styled.span`
    display: block;
    top: 50%;
    margin-top: -2px;
    &,
    &::before,
    &::after {
        width: 36px;
        height: 4px;
        background-color: ${props => props.theme.colors.GREEN};
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }
    &::before,
    &::after {
        content: '';
        display: block;
    }
    &::before {
        top: -10px;
    }
    &::after {
        bottom: -10px;
    }
`
const HamburguerBox = styled.span`
    width: 36px;
    height: 24px;
    display: inline-block;
    position: relative;
`
const Hamburguer = styled.button`

@media (min-width: 993px) {
  display: none;
}
  padding: 15px 15px;
  display: inline-flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

  &:hover {
    opacity: 0.7; }
  &.is-active:hover {
    opacity: 0.7; }

 &.is-active ${HamburguerInner},
  &.is-active ${HamburguerInner}::before,
  &.is-active ${HamburguerInner}::after {
    background-color: ${props => props.theme.colors.DARK_BLUE}; }

  

    &.hamburger--collapse ${HamburguerInner} {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &.hamburger--collapse ${HamburguerInner}:after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }
    &.hamburger--collapse ${HamburguerInner}:before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    
   &.hamburger--collapse.is-active ${HamburguerInner} {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &.hamburger--collapse.is-active ${HamburguerInner}:after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s 0.22s linear;
    }
    &.hamburger--collapse.is-active ${HamburguerInner}::before {
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
`
