import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import styled from 'styled-components'
import Image from 'gatsby-image'
import customMedia from '../../utils/media'
import Buttom from '../Shared/Button'

const ReactMarkdown = require('react-markdown')
const ImageContent = props => {
    const {
        sectionTitle,
        sectionContent,
        sectionImage,
        isReverse,
        ctaText,
        ctaSlug,
    } = props.data

    return (
        <Section>
            <Flex
                flexWrap="wrap"
                flexDirection={isReverse ? 'row-reverse' : ''}
            >
                <Box width={[1, 1, 1 / 2]}>
                    <ImageWrapper
                        isContentReverse={isReverse}
                        fluid={sectionImage.fluid}
                    />
                </Box>
                <Box width={[1, 1, 1 / 2]}>
                    <Content>
                        <ContentTitle>{sectionTitle}</ContentTitle>
                        <ReactMarkdown source={sectionContent.sectionContent} />
                        <Buttom isBlue slug={`/${ctaSlug}`} text={ctaText} />
                    </Content>
                </Box>
            </Flex>
        </Section>
    )
}

export default ImageContent

const Section = styled.section`
    margin: 100px auto 150px auto;
`
const Content = styled.div`
    padding: 40px 60px;
`
const ImageWrapper = styled(Image)`
    max-width: 600px;
    overflow: initial !important;

    ${({ isContentReverse }) =>
        isContentReverse && 'margin-inline-start: auto;'}

    ${customMedia.greaterThan('desktop')`
            &:before {
                content: '';
                border-top: 300px solid transparent;
                border-left: 600px solid ${({ theme }) =>
                    theme.colors.LIGHT_GREY};
                border-bottom: 300px solid transparent;
                position: absolute;
                top: -95px;
        
                ${({ isContentReverse }) =>
                    isContentReverse && 'transform: rotateY(180deg);'}
            }
            `}
`

const ContentTitle = styled.h2`
    font-size: 36px;
    color: ${({ theme }) => theme.colors.GREEN};
`
