import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import './layout.css'
import styled, { css } from 'styled-components'
import { ThemeProvider } from 'styled-components'
import theme from '../utils/theme'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <ThemeProvider theme={theme}>
            <BodyWrap>
                <Gutter />
                <Main role="main">
                    <Header />
                    {children}
                    <Footer />
                </Main>
                <Gutter />
            </BodyWrap>
        </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

const BodyWrap = styled.div`
    display: flex;
    justify-content: center;
`
const Main = styled.main`
    max-width: 1920px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 70px;
    flex-grow: 1;
    z-index: 1;
    width: 100%;
    position: relative;
`

const Gutter = styled.div`
    width: 100%;
    z-index: 9;
    flex: 1 1 0%;
    background: #f1f1f1;
`
