const theme = {
    breakpoints: ['40em', '52em', '64em'],
    colors: {
        BLUE: `#2B3990`,
        DARK_BLUE: `#212A64`,
        YELLOW: `#fdc300`,
        YELLOW_DARK: `#eab500`,
        LIGHT_GREY: `#F1F1F1`,
        GREY: `#4E4E4E`,
        DARK_GREY: `#231F20`,
        GREEN: `#8DC63F`,
        DARK_GREEN: `#86B742`,
    },
    fonts: {
        POPPINS: `"Poppins", san-serif`,
    },
}

export default theme
