import React from 'react'
import Hero from './Hero'
import CTA from './CTA'
import FAQList from './Faq'
import ImageContent from './ImageContent'
import Content from './Content'
import Team from './Team'

export default props => {
    const { modules } = props
    return (
        <section>
            {modules &&
                modules.map((module, index) => {
                    switch (module.__typename) {
                        case 'ContentfulComponentHero':
                            return (
                                <Hero
                                    key={`${module.__typename}-${index}`}
                                    data={module}
                                />
                            )
                            break
                        case 'ContentfulComponentCta':
                            return (
                                <CTA
                                    key={`${module.__typename}-${index}`}
                                    data={module}
                                />
                            )
                            break
                        case 'ContentfulComponentFaq':
                            return (
                                <FAQList
                                    key={`${module.__typename}-${index}`}
                                    data={module}
                                />
                            )
                            break
                        case 'ContentfulComponentImageContent':
                            return (
                                <ImageContent
                                    key={`${module.__typename}-${index}`}
                                    data={module}
                                />
                            )
                            break
                        case 'ContentfulComponentContent':
                            return (
                                <Content
                                    key={`${module.__typename}-${index}`}
                                    data={module}
                                />
                            )
                            break
                        case 'ContentfulTeamList':
                            return (
                                <Team
                                    key={`${module.__typename}-${index}`}
                                    data={module}
                                />
                            )
                            break
                        default:
                            return <>Componente no encontrado</>
                            break
                    }
                })}
        </section>
    )
}
