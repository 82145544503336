import styled from 'styled-components'

const Container = styled.div`
    margin: 0 auto;
    max-width: 1170px;
    padding: 0px 1.0875rem 1.45rem;
    padding-top: 0;
`

export default Container
