import React from 'react'

import Image from 'gatsby-image'
import styled, { css } from 'styled-components'
import CustomMedia from '../../utils/media'

import Buttom from '../Shared/Button'

const CTA = props => {
    const { backgroundImage, title, text, ctaText, ctaLink } = props.data
    return (
        <CTAWrapper>
            <CTAImage
                style={{
                    position: 'absolute',
                }}
                fluid={backgroundImage.fluid}
            />
            <CTAContent>
                <div>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </div>
                <CTAButtomWrapper>
                    <Buttom slug={ctaLink} text={ctaText} />
                </CTAButtomWrapper>
            </CTAContent>
        </CTAWrapper>
    )
}

const CTAWrapper = styled.div`
    position: relative;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
`

const CTAImage = styled(Image)`
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
`
const CTAContent = styled.div`
    z-index: 3;
    position: relative;
    color: #fff;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;

    ${CustomMedia.greaterThan('tablet')`
    padding:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
 `};

    h3 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 0;
    }
`

const CTAButtomWrapper = styled.div`
    margin-top: 15px;
    ${CustomMedia.greaterThan('tablet')`
        margin:0;
    `};
`

export default CTA
