import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import styled, { css } from 'styled-components'

const Buttom = props => {
    const { isInternal, slug, url, text } = props
    return isInternal ? (
        <CTALink {...props} to={slug}>
            {text}
        </CTALink>
    ) : (
        <CTAButton {...props} href={url}>
            {text}
        </CTAButton>
    )
}

Buttom.propTypes = {
    isInternal: PropTypes.bool,
    slug: PropTypes.string,
    url: PropTypes.any,
    text: PropTypes.string,
}

Buttom.defaultProps = {
    isInternal: true,
    slug: '',
    url: '',
    text: '',
}

const CTALink = styled(Link)`
    background: ${({ theme, isBlue }) =>
        isBlue ? theme.colors.BLUE : theme.colors.GREEN};
    color: #fff;
    padding: 10px 31px;
    text-decoration: none;
    border-radius: 28px;
    display: inline-block;
    font-size: 16px;
`

const CTAButton = styled.a`
    background: ${({ theme }) => theme.colors.GREEN};
    color: #fff;
    padding: 15px 31px;
    text-decoration: none;
    border-radius: 28px;
    font-size: 16px;
`

export default Buttom
