import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from './container'
import Hamburguer from './hamburguer'
import { Flex, Box } from 'reflexbox/styled-components'
import LogoTac from '../images/logo.svg'
import customMedia from '../utils/media'

const Header = ({ siteTitle }) => {
    const [toggleMenu, setToggleMenu] = useState(false)
    const data = useStaticQuery(graphql`
        {
            pages: allContentfulPage(
                filter: { showInNav: { nin: false } }
                sort: { fields: createdAt, order: ASC }
            ) {
                nodes {
                    pageName
                    slug
                    showInNav
                }
            }
        }
    `)
    const pages = data.pages
    return (
        <HeaderBox>
            <Container>
                <HeaderContent>
                    <Flex
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="center"
                        pl={15}
                        pr={[0, 20]}
                    >
                        <Box width={['100%', '100%', '25%']}>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Link to="/">
                                    <img
                                        css={{ marginBottom: 0 }}
                                        alt={siteTitle}
                                        src={LogoTac}
                                    />
                                </Link>
                                <Hamburguer
                                    isActive={toggleMenu ? `is-active` : ``}
                                    onClick={() => setToggleMenu(!toggleMenu)}
                                />
                            </Flex>
                        </Box>
                        <Box width={['100%', '100%', '75%']}>
                            <nav
                                css={{
                                    '@media(min-width:993px)': {
                                        textAlign: `right`,
                                        display: `block`,
                                    },
                                    display: toggleMenu ? `block` : `none`,
                                }}
                            >
                                {pages.nodes.map(item => (
                                    <NavItem
                                        key={item.pageName}
                                        title={item.pageName}
                                        as={Link}
                                        activeClassName="is-active"
                                        to={`/${item.slug}`}
                                    >
                                        {item.pageName}
                                    </NavItem>
                                ))}
                            </nav>
                        </Box>
                    </Flex>
                </HeaderContent>
            </Container>
        </HeaderBox>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header

const HeaderBox = styled.section`
    ${customMedia.greaterThan('desktop')`
        position: absolute;
        left: 0;
        right: 0;
        top: 35px;
        z-index: 555;
    `}

    & ${Container}{
        padding:0;
    }
`

const HeaderContent = styled.header`
    color: #fff;
    background: ${({ theme }) => theme.colors.DARK_GREY};
    ${customMedia.greaterThan('desktop')`
        background: transparent;
    `}
`

const NavItem = styled(Box)`
    color: #fff;
    padding: 0 17px;
    text-decoration: none;
    font-family: ${({ theme }) => theme.fonts.POPPINS};
    font-size: 15px;

    text-transform: uppercase;
    line-height: 75px;
    position: relative;
    display: flex;

    @media (min-width: 993px) {
        display: inline-flex;
    }

    &:before {
        content: '';
        width: 50%;
        height: 5px;
        background-color: ${({ theme }) => theme.colors.GREEN};
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        opacity: 0;
        margin: 0 auto;
        transform: translateY(5px);
        transition: all ease-in 0.3s;
    }

    &:hover,
    &:focus {
        &:before {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.is-active {
        &:before {
            opacity: 1;
            transform: translateY(0);
        }
    }
`
