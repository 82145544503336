import React from 'react'
import Image from 'gatsby-image'
import styled, { css } from 'styled-components'
import CustomMedia from '../../utils/media'
import Buttom from '../Shared/Button'
import HeroLinks from './links'

const Hero = props => {
    const {
        image,
        heroTitle,
        heroText,
        ctaText,
        ctaLink,
        linksItems,
    } = props.data

    return (
        <HeroWrapper>
            <HeroContent>
                <HeroTitle>{heroTitle}</HeroTitle>
                <HeroText>{heroText}</HeroText>
                {ctaText && <CTAButtom slug={ctaLink} text={ctaText} />}
            </HeroContent>
            <Image fluid={image.fluid} />
            {linksItems && <HeroLinks links={linksItems} />}
        </HeroWrapper>
    )
}

export default Hero

const HeroWrapper = styled.div`
    position: relative;
    ${CustomMedia.greaterThan('desktop')`
        height: 100vh;
    `};
    max-height: 900px;
    box-shadow: 0 0 0 1px #f1f1f1;
    overflow: hidden;
`

const HeroContent = styled.div`
    position: absolute;
    top: 50%;
    z-index: 5;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    transform: translateY(-50%);
`

const HeroTitle = styled.h1`
    ${CustomMedia.greaterThan('desktop')`
    font-size: 50px;
  `}
`

const HeroText = styled.p`
    ${CustomMedia.greaterThan('desktop')`
     max-width: 440px;
  `}
    margin: 0 auto;
`

const CTAButtom = styled(Buttom)`
    margin-top: 35px;
`
