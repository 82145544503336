import React from 'react'
import styled from 'styled-components'
import Container from './container'
import { Flex, Box } from 'reflexbox/styled-components'
import CustomMedia from '../utils/media'

const Footer = () => {
    return (
        <FooterWrap>
            <Container>
                <Flex flexWrap="wrap">
                    <Box width={[1, 1, 1 / 2]}>
                        <div css={{ padding: '44px 0' }}>
                            <h4>Ubicación</h4>
                            <p>
                                Urbanización Los Ángeles <br />
                                Esquina de Ave. Los Periodistas y Calle Tael
                                <br />
                                Ciudad Panamá, Panamá
                            </p>
                        </div>
                        <div>
                            <h4>Contácto</h4>
                            <p css={{ marginBottom: 0 }}>
                                Tel: (507) 214 - 4645
                            </p>
                            <a href={'mailto:rachurra@tacenlinea.com'}>
                                rachurra@tacenlinea.com
                            </a>
                        </div>
                        <br />
                        <span>
                            © {new Date().getFullYear()} TAC Internacional Inc.
                            S.A.
                        </span>
                    </Box>
                    <Box width={[1, 1, 1 / 2]}>
                        <>
                            <MapIframe
                                src="https://www.google.com/maps/d/u/0/embed?mid=1sU4nDvnrEmU9RoIIZnGsZoAeSRK_gJ5_&z=16"
                                css={{ width: '100%' }}
                                height="480"
                            ></MapIframe>
                        </>
                    </Box>
                </Flex>
            </Container>
        </FooterWrap>
    )
}

export default Footer

const FooterWrap = styled.footer`
    background: ${({ theme }) => theme.colors.DARK_GREY};
    color: whitesmoke;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    & a {
        color: ${({ theme }) => theme.colors.GREEN};
        margin: 4px;
        text-decoration: none;
    }

    & h4 {
        color: ${({ theme }) => theme.colors.GREEN};
    }
    & a {
        color: whitesmoke;
    }
`
const MapIframe = styled.iframe`
    border: none;
    margin-bottom: 0;
    ${CustomMedia.greaterThan('tablet')`
    position: absolute;
    width: 50%;
    top: -47px;
 `};
`
